import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async get(lawyerId) {
    let result = await axios.get(
      `${API_ROUTES.audiencesexpiredlawyer.get}/${lawyerId}`
    );

    return result;
  }
};
